<template>
  <div class="binanceDeal">
    <div v-title data-title="币安交易"></div>
    <div class="Navbar">
      <img @click="gotoback" :src="imgUrl + 'left-arrow2.png'" />
      <div class="title">币安交易</div>
    </div>

    <div class="test_two_box">
      <video id="myVideo" class="video-js" style="width:100%">
        <source :src="`${videoUrl}buybian.mp4`" type="video/mp4">
      </video>
    </div>

    <p style="color:var(--li-colortext)">视频教学</p>
    <p style="color:var(--li-colortext)">点击播放按钮观看教学视频</p>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.initVideo();
  },
  methods: {
    gotoback () {
      this.$router.go(-1)
      // uni.navigateBack({});
    },
    initVideo () {
      //初始化视频方法
      let myPlayer = this.$video(myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        autoplay: "muted",
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",

        //设置视频播放器的显示高度（以像素为单位）
        height: "200px"

      });
    },
  }
}
</script>

<style lang='less'>
body {
  background-color: #f5f5f5;
}

.binanceDeal {
  .Navbar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 43px;
    width: 100%;
    background-color: #fff;
    padding: 0 20px;

    >img {
      height: 20px;
      width: 20px;
    }

    .title {
      flex: 1;
      text-align: center;
      color: #000;
    }
  }

  .test_two_box {
    display: flex;
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 40px;
    padding: 0 20px;
  }

  p {
    text-align: center;
    color: #000;
    font-size: 14px;
  }
}
</style>